import { Modal, Skeleton, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from 'src/components/button';
import { BaseText } from 'src/components/typography';
import {
  EHomeworkType,
  TActivityData,
  TGetHomeworkResultParams,
  TGetAiConversationParams,
  THomework,
  TTaskItemData,
  TUpdateAiConversationParams,
} from 'src/interfaces/clients-interface';
import { TFile } from 'src/interfaces/common-interface';
import { TRootState, useAppDispatch } from 'src/stores';
import { EClientsAction, getHomeworkResultAction, getAiConversationAction } from 'src/stores/clients';
import ActivityCard from './ActivityCard';
import HomeworkActivityItem from './HomeworkActivityItem';
import HomeworkInfo from './HomeworkInfo';
import QuestionResults from './QuestionResults';
import './HomeworkHistoryDetailsModal.scss';
import { downloadHomeworkFile } from 'src/utils/common-utils';
import { EHomeworkResultStatus } from 'src/variables/enum-variables';
import { LikeFilled, LikeOutlined, DislikeFilled, DislikeOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { updateAiConversationDataAction } from 'src/stores/clients/clients-actions';

interface IProps {
  open: boolean;
  data?: TActivityData;
  historyData?: TTaskItemData;
  onPreviewFiles?: (files: TFile[]) => void;
  onClose: () => void;
}

const HomeworkHistoryDetailsModal = ({ open, data, historyData, onClose, onPreviewFiles }: IProps) => {
  const dispatch = useAppDispatch();
  const isFromAssigned = !!historyData;
  
  // State to track the index for showing the refined input or thank-you message
  const [refinedInputIndex, setRefinedInputIndex] = useState<number | null>(null);
  const [submitted, setSubmitted] = useState<boolean[]>([]); // Tracks which indexes have submitted responses

  const { homeworkDetails, homeworkResult, loading, downloading, clientId, clientName, aiConversationData } = useSelector((state: TRootState) => ({
    homeworkDetails: state.clients.homeworkDetail,
    homeworkResult: state.clients.homeworkResult,
    loading: state.loading[EClientsAction.GET_HOMEWORK_RESULT],
    downloading: state.loading[EClientsAction.DOWNLOAD_HOMEWORK_FILE],
    clientId: state.clients.clientInfo?.clientId,
    clientName: state.clients.clientInfo?.firstName,
    aiConversationData: state.clients.aiConversationData
  }));

  const homework = isFromAssigned ? homeworkResult?.homework : homeworkDetails?.homework;
  const formattedActivityData: TActivityData | undefined = homeworkResult && {
    id: homeworkResult.id,
    resultText: homeworkResult.clientResponse?.responseText,
    comment: homeworkResult.feedback,
    createdAt: homeworkResult.createdAt,
    filesUrls: homeworkResult.clientAnswerImages,
    homeworkType: homeworkResult.homework?.type,
    rate: homeworkResult.rate,
    rejectText: homeworkResult.rejectReason,
    status: homeworkResult.status,
    classification: homeworkResult.classification,
  };
  const activityData = data ?? formattedActivityData;
  const isSkipped = activityData?.status === EHomeworkResultStatus.SKIPPED;

  const getHomeworkResult = (params: TGetHomeworkResultParams) => {
    dispatch(getHomeworkResultAction(params));
  };

  const getAiConversation = (params: TGetAiConversationParams) => {
    dispatch(getAiConversationAction(params));
  };

  const handleThumbsUpClick = (index: number) => {
    if (aiConversationData) {
      const conversationItem = aiConversationData[index];
      if (conversationItem.thumbsUp !== true && clientId) {
        const updatedAiConversationData = [...aiConversationData];
        updatedAiConversationData[index] = {
          ...conversationItem,
          thumbsUp: true,
        };

        const updatedParameters: TUpdateAiConversationParams = {
          messageId: aiConversationData[index].id,
          thumbsUp: true,
          clientId: clientId,
        };

        dispatch(updateAiConversationDataAction(updatedParameters));

        const newSubmitted = [...submitted];
        newSubmitted[index] = false;
        setSubmitted(newSubmitted);

        if (refinedInputIndex === index) {
          setRefinedInputIndex(null);
        }
      }
    }
  };

  const handleThumbsDownClick = (index: number) => {
    if (aiConversationData) {
      const conversationItem = aiConversationData[index];
      if (conversationItem.thumbsUp !== false && clientId) {
        const updatedAiConversationData = [...aiConversationData];
        updatedAiConversationData[index] = {
          ...conversationItem,
          thumbsUp: false,
        };

        const updatedParameters: TUpdateAiConversationParams = {
          messageId: aiConversationData[index].id,
          thumbsUp: false,
          clientId: clientId,
        };

        dispatch(updateAiConversationDataAction(updatedParameters));
        setRefinedInputIndex(index);
      }
    }
  };

  const handleDownloadFile = (attachmentId: string, fileName?: string) => {
    const homeworkAssignId = isFromAssigned ? historyData?.homeworkAssignId : homeworkDetails?.id;

    downloadHomeworkFile(
      {
        attachmentId,
        homeworkAssignId: homeworkAssignId ?? '',
        homeworkResultId: activityData?.id ?? '',
        fileName,
      },
      dispatch,
    );
  };

  useEffect(() => {
    let params: TGetHomeworkResultParams | undefined;
    if (historyData) {
      params = {
        id: historyData.homeworkAssignId ?? '',
        homeworkHistoryId: historyData.id ?? '',
      };

      if (historyData.homeworkAssignId && clientId) {
        const aiParams: TGetAiConversationParams = {
          homeworkAssignId: historyData.homeworkAssignId,
          clientId: clientId,
        };
        getAiConversation(aiParams);
      }
    }

    if (data) {
      params = {
        id: homeworkDetails?.id ?? '',
        homeworkHistoryId: data?.id ?? '',
      };
    }

    if (params) {
      getHomeworkResult(params);
    }
  }, [historyData, data]);

  const renderAiConversation = () => {
    if (!aiConversationData || aiConversationData.length === 0) {
      return <div>No AI Conversation Data Available</div>;
    }

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    };

    const isSameDay = (date1: string, date2: string) => {
      return new Date(date1).toDateString() === new Date(date2).toDateString();
    };

    const isToday = (dateString: string) => {
      const date = new Date(dateString);
      const today = new Date();
      return date.toDateString() === today.toDateString();
    };

    let previousDate: string | null = null;

    return (
      <div className="conversation">
        {aiConversationData.map((data, index) => {
          const showDateSeparator = !isSameDay(previousDate ?? '', data.createdAt);
          previousDate = data.createdAt;

          return (
            <div key={index}>
              {showDateSeparator && (
                <div className="date-separator">
                  <span>{isToday(data.createdAt) ? 'Today' : formatDate(data.createdAt)}</span>
                  <hr />
                </div>
              )}
              <div className={`message ${data.role} ${data.softDelete ? 'softDeleted' : ''}`}>
                <div className="role">{data.role === 'user' ? `${clientName}:` : 'jAImee:'}</div>
                <div className="content">{data.content}</div>
                {data.role === 'assistant' && renderThumbsControls(index, data.thumbsUp)}
                {renderAdditionalInputOrThankYou(index)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderThumbsControls = (index: number, thumbsUp: boolean | null) => (
    <div className="thumbs-container">
      <Button
        type="link"
        icon={thumbsUp === true ? <LikeFilled style={{ color: '#48abe2' }} /> : <LikeOutlined style={{ color: 'gray' }} />}
        onClick={() => handleThumbsUpClick(index)}
      />
      <Button
        type="link"
        icon={thumbsUp === false ? <DislikeFilled style={{ color: '#ff7777' }} /> : <DislikeOutlined style={{ color: 'gray' }} />}
        onClick={() => handleThumbsDownClick(index)}
      />
    </div>
  );

  const renderAdditionalInputOrThankYou = (index: number) => {
    if (submitted[index]) {
      return <div>Thank you for your feedback!</div>;
    } else if (refinedInputIndex === index) {
      return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <textarea
            id={`textarea-${index}`}
            style={{
              width: '100%',
              boxSizing: 'border-box',
              marginTop: '10px',
              marginBottom: '10px',
            }}
            placeholder="Please provide a refined response..."
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              onClick={() => {
                const textarea = document.getElementById(`textarea-${index}`) as HTMLTextAreaElement;
                const additionalComments = textarea ? textarea.value : '';
                handleRefinedResponseSubmit(index, additionalComments);
              }}
              style={{
                float: 'right',
                boxSizing: 'border-box',
                padding: '5px 10px',
              }}
            >
              <CheckCircleOutlined /> Submit
            </button>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleRefinedResponseSubmit = (index: number, additionalComments: string) => {
    if (aiConversationData && aiConversationData[index] && clientId) {
      const updatedParameters: TUpdateAiConversationParams = {
        messageId: aiConversationData[index].id,
        thumbsUp: false,
        clientId: clientId,
        additionalComments: additionalComments,
      };

      dispatch(updateAiConversationDataAction(updatedParameters));
    }

    const newSubmitted = [...submitted];
    newSubmitted[index] = true;
    setSubmitted(newSubmitted);
    setRefinedInputIndex(null);
  };

  return (
    <Modal
      className="HomeworkHistoryDetailsModal"
      open={open}
      width={1020}
      closable={false}
      footer={null}
      onCancel={onClose}
    >
      {loading ? (
        <>
          <Skeleton />
          <center><Spin /></center>
        </>
      ) : (
        <>
          <BaseText type="title" textAlign="center" className="HomeworkHistoryDetailsModal__title">
            {homework?.title}
          </BaseText>
          {isFromAssigned && <HomeworkInfo homework={homework as THomework} />}

          {activityData && (
            <HomeworkActivityItem data={activityData} isCard={false}>
              {homework?.type === EHomeworkType.QUESTIONNAIRES && !isSkipped && (
                <QuestionResults homework={homeworkResult} />
              )}
              {isFromAssigned && homework?.type === EHomeworkType.AI && renderAiConversation()}
              {homework?.type !== EHomeworkType.QUESTIONNAIRES && activityData.resultText && !isSkipped && (
                <ActivityCard
                  data={activityData}
                  type="result"
                  className="HomeworkHistoryDetailsModal__result"
                  isCard={false}
                  downloading={downloading}
                  onPreviewFiles={onPreviewFiles}
                  onDownload={handleDownloadFile}
                />
              )}
              {(activityData.comment || activityData.rejectText || activityData.rate) && (
                <ActivityCard
                  type="comment"
                  isCard={false}
                  className="HomeworkHistoryDetailsModal__comment"
                  data={{
                    comment: activityData.comment,
                    rate: activityData.rate,
                    rejectText: activityData.rejectText,
                    title: 'Client’s comment',
                    id: 'fake Id',
                    classification: activityData.classification,
                  }}
                />
              )}
            </HomeworkActivityItem>
          )}
        </>
      )}
      <Button type="primary" className="HomeworkHistoryDetailsModal__action" onClick={onClose}>
        Close
      </Button>
    </Modal>
  );
};

export default HomeworkHistoryDetailsModal;
